<template>
<v-toolbar elevation="0"  fluid class="blue lighten-4 d-flex justify-end">
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn small class="mx-1" v-bind="attrs" v-on="on">
        批量导入/导出<v-icon>mdi-menu-down</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item>
        <v-list-item-title>
          <!-- @click="importFile" -->
          <v-btn small plain @click="importDialog">批量导入</v-btn>
          <input type="file" ref="fileIntercom" style="display:none;" accept=".xls,.xlsx" @change="insertFile">
        </v-list-item-title>
      </v-list-item>
      <v-list-item>
        <v-list-item-title>
          <v-btn small plain>导出通讯录</v-btn>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
  <v-btn class="mx-1" small>快速邀请同事</v-btn>
  <v-btn :disabled="authUser.user_role!='creator'" class="mx-1" small @click="setBoss">设置公司老板</v-btn>
  <v-btn class="mx-1" small color="primary" @click="addEmployee">新增</v-btn>
  <NewEmployeeDlg ref="newEmployee" />

  
      <v-form
        ref="form"
       
       >

        
        <v-row justify="center">
          <v-dialog
            v-model="dialog"
            persistent
            max-width="500px"
          >
            
            <v-card>
              <v-card-title style="border-bottom:1px solid #ccc">
                <span class="text-h5" >批量导入</span>
              </v-card-title>
              <v-card-text>
                <v-container style="margin-top:20px;">
                  <v-row>
                    
                    <v-col cols="9">
                        <div>1.请先下载导入模板，批量填写员工信息</div>
                    </v-col>
                    <v-col cols="3">
                        <a class="down_load" href="/doc/template.xlsx" download>点击下载</a>
                        <!-- 这里的href路径是相对与public文件夹的不是组件的路径-->
                    </v-col>
                    <v-col cols="9">
                        <div>2.上传填写好的员工信息</div>
                    </v-col>
                    <input ref="fileUpload" style="display:none;" type="file" accept=".xls,.xlsx" @change="fileUpload">
                    <!-- <input type="file" ref="fileIntercom" style="display:none;" accept=".xls,.xlsx" @change="insertFile"> -->
                    <v-col cols="3">
                        <v-btn  outlined color="indigo" text @click="upLoadFile" >上传文件</v-btn>
                    </v-col>

                    <v-col cols="12">
                      <div style="">{{fileUrl}}</div>
                    </v-col>
                    
                  </v-row>
                </v-container>
                
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="dialog = false"
                >
                  取消
                </v-btn>
                 
                <!-- <v-btn
                  color="blue darken-1"
                  text
                  @click="dialog = false"
                >
                  确定
                </v-btn> -->
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </v-form>


</v-toolbar>
</template>

<script>
import { 
  mapGetters,
  mapActions 
} from 'vuex'
import endpoints from "@/config/endpoints";

export default {
  name: 'GroupHeader',
  components: {
    NewEmployeeDlg: () => import('@/components/admin/employee/NewEmployee.vue')
  },
  data(){
    return{
      dialog: false,   //批量导入弹层

      file_uri:"",

      fileUrl:'', //上传文件成功以后的文件路径
    }
  },
  props:['department'],
  computed: {
    ...mapGetters("fileUpload", ["intercomFiles"]),
    ...mapGetters("auth", ["authUser"])
  },
  methods: {
    ...mapActions("fileUpload", ["filesUpload"]),
    ...mapActions('app', ['getSettingCall', 'setSettingCall']),
    // ...mapActions("fileUpload", ["userImportExcel"]),
    addEmployee() {
      this.$refs.newEmployee.open(this.department)
    },
    setBoss() {
      this.$emit('setBoss')
    },
    //导入数据按钮
    importFile() {
        this.$refs.fileIntercom.click();
    },

    
    //添加excel文件
    insertFile(e) {
      this.file = e.target.files[0];
      var formData = new FormData();
      formData.append("files", this.file);
      this.fileUpload({
        type: "intercom",
        value: formData,
      })
      .then(res=>{
        //console.log(this.intercomFiles,'intercomFiles')
        
        if(res){
          this.file_uri = this.intercomFiles[0].file_uri;
          console.log (this.intercomFiles[0].file_uri)
          // this.userImportExcel({
          //   file_uri:this.file_uri
          // }).then(res=>{
          //   console.log(res,'resres---');
          // });
        }
      })
      
    },

    importDialog(){
      this.dialog = true;
    },

    //上传文件
    upLoadFile(){
      this.$refs.fileUpload.click();
    },
    fileUpload() {
      const file = this.$refs.fileUpload.files[0];
      let formData = new FormData();
      formData.append('files', file);
      this.filesUpload(formData).then(res => {
        this.setSettingCall({
          st_category_id: 100,
          st_category_type: 100,
          st_value: JSON.stringify(res[0])
        });
         
        this.fileUrl = `${endpoints.FILE_DOWNLOAD}/${res[0].file_id}`
        
      });
    },


  }
}
</script>

<style scoped>
.down_load{
  line-height:36px;
  border-radius:5px; 
  text-decoration:none;
  display:inline-block;
  border: 1px solid #ccc;
  height: 36px;
  width: 96.2px;
  padding: 0 16px;
}
</style>
